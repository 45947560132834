@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Spoqa Han Sans Neo" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ant-menu.ant-menu-sub.ant-menu-vertical {
  padding: 3px;
  min-width: 290px;
  max-width: 330px;
}

